function isExist(values: TType[], type: TType) {
  return values.includes(type)
}

type TType = TransactionStatus | InvestStatus | SubAdminStatus

export const StatusColorFinder = (type: TType): ButtonColorType => {
  if (isExist(['confirmed', 'active', 'activated'], type)) {
    return 'success'
  } else if (isExist(['declined', 'expired', 'inactive'], type)) {
    return 'danger'
  } else if (isExist(['pending', 'waitingForPayment'], type)) {
    return 'warning'
  }

  return 'default'
}
