import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiChevronLeft } from 'react-icons/bi'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  name: string
  username: string
  password: string
  email: string
}

export default function AddSubAdminPage() {
  const queryClient = useQueryClient()
  const [form, setForm] = useState<Form>({
    name: '',
    username: '',
    password: '',
    email: '',
  })

  const [errors, setErrors] = useState<Form>({
    name: '',
    username: '',
    password: '',
    email: '',
  })

  const addSubAdmin = useMutation<{ message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/subAdmin', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('subAdmin')
      },
    },
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!form.name || !form.username || !form.password || !form.email) {
      setErrors((prev) => ({
        ...prev,
        name: 'Name is required',
        username: 'Username is required',
        password: 'Password is required',
        email: 'Email is required',
      }))
      return
    }

    toast.promise(addSubAdmin.mutateAsync(form), {
      loading: 'Adding new sub admin...',
      success: (res) => {
        setForm({
          name: '',
          username: '',
          password: '',
          email: '',
        })
        return res.message
      },
      error: (err) => err.message,
    })
  }

  return (
    <div>
      <h2 className='text-lg font-medium mb-10'>
        <Link to='/sub-admins' className='mr-2'>
          <BiChevronLeft className='inline-block' size={30} />
        </Link>
        Add New Sub Admin
      </h2>

      <form onSubmit={handleSubmit} className='grid gap-10'>
        <Input
          label='Name'
          name='name'
          value={form.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <Input
          label='Username'
          name='username'
          value={form.username}
          onChange={handleChange}
          error={!!errors.username}
          helperText={errors.username}
        />
        <Input
          label='Email'
          name='email'
          type='email'
          value={form.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <Input
          label='Password'
          name='password'
          value={form.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
        />
        <Button disabled={addSubAdmin.isLoading} fullWidth>
          Add
        </Button>
      </form>
    </div>
  )
}
