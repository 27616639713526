type Props = React.SVGProps<SVGSVGElement>
export default function PayoutIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_420_659)'>
        <path
          d='M15.8333 0H12.5C10.2025 0 8.33333 1.86917 8.33333 4.16667V15.8333C8.33333 18.1308 10.2025 20 12.5 20H15.8333C18.1308 20 20 18.1308 20 15.8333V4.16667C20 1.86917 18.1308 0 15.8333 0ZM18.3333 15.8333C18.3333 17.2117 17.2117 18.3333 15.8333 18.3333H12.5C11.1217 18.3333 10 17.2117 10 15.8333V4.16667C10 2.78833 11.1217 1.66667 12.5 1.66667H15.8333C17.2117 1.66667 18.3333 2.78833 18.3333 4.16667V15.8333ZM5.83333 3.33333V17.5C5.83333 17.721 5.74554 17.933 5.58926 18.0893C5.43297 18.2455 5.22101 18.3333 5 18.3333C4.77899 18.3333 4.56702 18.2455 4.41074 18.0893C4.25446 17.933 4.16667 17.721 4.16667 17.5V3.33333C4.16667 3.11232 4.25446 2.90036 4.41074 2.74408C4.56702 2.5878 4.77899 2.5 5 2.5C5.22101 2.5 5.43297 2.5878 5.58926 2.74408C5.74554 2.90036 5.83333 3.11232 5.83333 3.33333ZM1.66667 5.83333V15C1.66667 15.221 1.57887 15.433 1.42259 15.5893C1.26631 15.7455 1.05435 15.8333 0.833333 15.8333C0.61232 15.8333 0.400358 15.7455 0.244078 15.5893C0.0877974 15.433 0 15.221 0 15V5.83333C0 5.61232 0.0877974 5.40036 0.244078 5.24408C0.400358 5.0878 0.61232 5 0.833333 5C1.05435 5 1.26631 5.0878 1.42259 5.24408C1.57887 5.40036 1.66667 5.61232 1.66667 5.83333Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_420_659'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
