type Props = {
  title: string
  value?: string | number
  icon: string
}

export default function AnalyticCard({ icon, title, value = '' }: Props) {
  return (
    <div className='card'>
      <div className='flex justify-between items-center'>
        <span className='text-xs font-medium'>{title}</span>
        <img src={icon} alt='' />
      </div>
      <h2 className='font-medium text-sm'>{value?.toLocaleString()}</h2>
      {/* <p className='text-success mt-6 font-medium text-[10px]'>
        <HiOutlineArrowTrendingUp className='inline mr-1' />
        N/A <span className='text-secondary text-[8px]'>/month</span>
      </p> */}
    </div>
  )
}
