import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { privateRequest } from 'config/axios.config'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiChevronLeft } from 'react-icons/bi'
import { useMutation, useQueryClient } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  name: string
  status?: Option
  id: string
}

type Error = {
  name: string
  status: string
  id: string
}

const options: Option[] = [
  { label: 'Activated', value: 'activated' },
  { label: 'Inactive', value: 'inactive' },
]

export default function EditSubAdminPage() {
  const data = useLocation().state?.data
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const [form, setForm] = useState<Form>({
    name: '',
    id: '',
  })

  const [errors, setErrors] = useState<Error>({
    name: '',
    status: '',
    id: '',
  })

  useEffect(() => {
    if (data) {
      setForm({
        name: data.name,
        status: options.find((option) => option.value === data.status),
        id: data._id,
      })
    }
  }, [data])

  const addSubAdmin = useMutation<{ message: string }, Error, Error>(
    async (payload) => {
      try {
        const res = await privateRequest.patch('admin/subAdmin', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('subAdmin')
        navigate('/sub-admins')
      },
    },
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }))
  }

  const selectHandler = ({ target: { value, name } }: SelectElement) => {
    setForm((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!form.name || !form.status) {
      setErrors((prev) => ({
        ...prev,
        name: 'Name is required',
        status: 'Status is required',
      }))
      return
    }

    if (!form.id) {
      toast.error('Invalid ID or Id is required')
      return
    }

    const payload = {
      name: form.name,
      status: form.status.value,
      id: form.id,
    }

    toast.promise(addSubAdmin.mutateAsync(payload), {
      loading: 'Updating sub admin...',
      success: (res) => res?.message ?? 'Sub admin updated successfully',
      error: (err) => err.message ?? 'Something went wrong',
    })
  }

  return (
    <div>
      <h2 className='text-lg font-medium mb-10'>
        <Link to='/sub-admins' className='mr-2'>
          <BiChevronLeft className='inline-block' size={30} />
        </Link>
        Edit Sub Admin
      </h2>

      <form onSubmit={handleSubmit} className='grid gap-10'>
        <Input
          label='Name'
          name='name'
          value={form.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <Select
          label='Status'
          name='status'
          options={options}
          value={form.status}
          onChange={selectHandler}
          error={!!errors.status}
          helperText={errors.status}
        />
        <Button disabled={addSubAdmin.isLoading} fullWidth>
          Add
        </Button>
      </form>
    </div>
  )
}
