import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'

import bitcoinFundIcon from 'images/bitcoin-fund-icon.png'
import tetherIcon from 'images/tether-icon.png'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiChevronLeft } from 'react-icons/bi'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  totalCurrencyPair: string
  dailyPips: string
  totalInvested: string
  totalValue: string
  totalLoss: string
  totalYield: string
  createdAt?: Date
}

export default function AddForexPage() {
  const queryClient = useQueryClient()
  const [form, setForm] = useState<Form>({
    totalCurrencyPair: '',
    dailyPips: '',
    totalInvested: '',
    totalValue: '',
    totalLoss: '',
    totalYield: '',
    createdAt: new Date(),
  })

  const [errors, setErrors] = useState<Form>({
    totalCurrencyPair: '',
    dailyPips: '',
    totalInvested: '',
    totalValue: '',
    totalLoss: '',
    totalYield: '',
  })

  const addCrypto = useMutation(
    async () => {
      try {
        const res = await privateRequest.post('admin/forex', form)
        console.log(res)
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        setForm({
          totalCurrencyPair: '',
          dailyPips: '',
          totalInvested: '',
          totalValue: '',
          totalLoss: '',
          totalYield: '',
          createdAt: new Date(),
        })
        queryClient.invalidateQueries('forex')
      },
    },
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (isNaN(+value)) return
    setForm((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !form.totalCurrencyPair ||
      !form.dailyPips ||
      !form.totalInvested ||
      !form.totalValue ||
      !form.totalLoss ||
      !form.totalYield
    ) {
      setErrors((prev) => ({
        ...prev,
        totalCurrencyPair: !form.totalCurrencyPair ? 'Total Currency Pair is required' : '',
        dailyPips: !form.dailyPips ? 'Daily Pips is required' : '',
        totalInvested: !form.totalInvested ? 'Total Invested is required' : '',
        totalValue: !form.totalValue ? 'Total Value is required' : '',
        totalLoss: !form.totalLoss ? 'Total Loss is required' : '',
        totalYield: !form.totalYield ? 'Total Yield is required' : '',
      }))
      return
    }
    toast.promise(addCrypto.mutateAsync(), {
      loading: 'Adding new forex...',
      success: 'Forex added successfully',
      error: 'Something went wrong',
    })
  }

  return (
    <div>
      <h2 className='text-lg font-medium mb-10'>
        <Link to='/forex' className='mr-2'>
          <BiChevronLeft className='inline-block' size={30} />
        </Link>
        Add New Price{' '}
      </h2>

      <form onSubmit={submitHandler} className='grid grid-cols-2 gap-20'>
        <Input
          label='Total Invested'
          afterFix={<img className='-translate-y-1' src={bitcoinFundIcon} />}
          value={form.totalInvested}
          onChange={handleChange}
          name='totalInvested'
          error={!!errors.totalInvested}
          helperText={errors.totalInvested}
        />
        <Input
          label='Total Currency Pair'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalCurrencyPair}
          onChange={handleChange}
          name='totalCurrencyPair'
          error={!!errors.totalCurrencyPair}
          helperText={errors.totalCurrencyPair}
        />
        <Input
          label='Daily Pips'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.dailyPips}
          onChange={handleChange}
          name='dailyPips'
          error={!!errors.dailyPips}
          helperText={errors.dailyPips}
        />
        <Input
          label='Total Value'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalValue}
          onChange={handleChange}
          name='totalValue'
          error={!!errors.totalValue}
          helperText={errors.totalValue}
        />
        <Input
          label='Total Yield'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalYield}
          onChange={handleChange}
          name='totalYield'
          error={!!errors.totalYield}
          helperText={errors.totalYield}
        />
        <Input
          label='Total Losses'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalLoss}
          onChange={handleChange}
          name='totalLoss'
          error={!!errors.totalLoss}
          helperText={errors.totalLoss}
        />
        <Button disabled={addCrypto.isLoading} fullWidth>
          Add
        </Button>
      </form>
    </div>
  )
}
