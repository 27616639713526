import Tabs from 'components/Tabs'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { privateRequest } from 'config/axios.config'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

const tabs: TabOption[] = [
  {
    label: 'Low  Yield',
    value: 'low',
  },
  {
    label: 'Medium Yield',
    value: 'medium',
  },
  {
    label: 'High Yield',
    value: 'high',
  },
]

const currencies: Option[] = [
  {
    label: 'BTC',
    value: 'BTC',
  },
  {
    label: 'ETH',
    value: 'ETH',
  },
  {
    label: 'USDT',
    value: 'USDT',
  },
]

interface PackageForm {
  id: string
  minAmount: string
  apy: string
  duration: string
  yield: string
  roiPercentage: string
  freeAirDrop: string
  redemption: string
  contract: string
  currencies: Option[]
  status: string
}

type PackagePayload = {
  id: string
  minAmount: string
  apy: string
  duration: string
  yield: string
  roiPercentage: string
  freeAirDrop: string
  redemption: string
  contract: string
  currencies: string[]
  status: string
}

export default function PackagesPage() {
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useState<TabOption>(tabs[0])
  const [form, setForm] = useState<PackageForm>({
    id: '',
    minAmount: '',
    apy: '',
    duration: '',
    yield: '',
    roiPercentage: '',
    freeAirDrop: '',
    redemption: '',
    contract: '',
    currencies: [],
    status: '',
  })

  const { data } = useQuery<PackageResponse[], Error>('packages', async () => {
    try {
      const res = await privateRequest.get('admin/packages')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const updatePackage = useMutation<{ message: string }, Error, PackagePayload>(
    async (payload) => {
      try {
        const res = await privateRequest.patch('admin/packages', payload)
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('packages')
      },
    },
  )

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const currenciesSelectHandler = ({ target: { value, name } }: SelectElement) => {
    setForm((prev) => {
      const findIndex = prev.currencies?.findIndex((el) => el.value === value.value)
      let newVal = prev.currencies
      if (findIndex !== -1) {
        newVal = newVal?.filter((el) => el.value !== value.value)
      } else {
        newVal = newVal.concat(value)
      }
      return { ...prev, [name]: newVal }
    })
  }

  const packages: TabOption[] = data?.length
    ? data?.map((el) => ({ label: el.type?.toUpperCase(), value: el.type }))
    : []

  useEffect(() => {
    if (packages?.[0] && !activeTab.value) {
      setActiveTab(packages[0])
    }
  }, [data])

  const selectedPackage = data?.find((el) => el.type === activeTab.value)

  useEffect(() => {
    if (selectedPackage) {
      setForm((prev) => ({
        ...prev,
        ...selectedPackage,
        id: selectedPackage._id,
        currencies: selectedPackage.currencies.map((el) => ({ label: el, value: el })),
      }))
    }
  }, [selectedPackage, activeTab])

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const payload = {
      ...form,
      currencies: form.currencies?.map((el) => el.value),
    }
    toast.promise(updatePackage.mutateAsync(payload), {
      loading: 'Updating package...',
      success: 'Package updated successfully',
      error: 'Error updating package',
    })
  }

  return (
    <div>
      <Tabs options={packages} selectHandler={setActiveTab} selected={activeTab} />

      <form onSubmit={submitHandler} className='mt-20 grid grid-cols-2 gap-20'>
        <Input
          label='Minimum Amount'
          value={form.minAmount}
          onChange={handleFormChange}
          name='minAmount'
        />
        <Input label='Yield' value={form.yield} onChange={handleFormChange} name='yield' />
        <Input label='APY' value={form.apy} onChange={handleFormChange} name='apy' />
        <Input
          label='ROI'
          value={form.roiPercentage}
          onChange={handleFormChange}
          name='roiPercentage'
        />
        <Input label='Duration' value={form.duration} onChange={handleFormChange} name='duration' />
        <Input
          label='Redemption'
          value={form.redemption}
          onChange={handleFormChange}
          name='redemption'
        />
        <Select
          label='Currency'
          multiple
          values={form.currencies}
          options={currencies}
          name='currencies'
          onChange={currenciesSelectHandler}
        />
        <Input label='Contract' value={form.contract} onChange={handleFormChange} name='contract' />
        <Input
          label='Free Airdrop'
          value={form.freeAirDrop}
          onChange={handleFormChange}
          name='freeAirDrop'
        />
        <Button disabled={updatePackage.isLoading}>Update</Button>
      </form>
    </div>
  )
}
