import CryptoIcon from 'components/icons/CryptoIcon'
import ForexIcon from 'components/icons/ForexIcon'
import HardAssetIcon from 'components/icons/HardAssetIcon'
import InvestIcon from 'components/icons/InvestIcon'
import PackageIcon from 'components/icons/PackageIcon'
import PayoutIcon from 'components/icons/PayoutIcon'
import SettingsIcon from 'components/icons/SettingsIcon'
import StockIcon from 'components/icons/StockIcon'
import WithdrawIcon from 'components/icons/WithdrawIcon'
import { BiTransferAlt } from 'react-icons/bi'
import { HiOutlineUsers } from 'react-icons/hi2'
import { RxDashboard } from 'react-icons/rx'

export const drawerLinks: DrawerLink[] = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: RxDashboard,
  },
  {
    label: 'Invests',
    link: '/invests',
    icon: InvestIcon,
  },
  {
    label: 'Pending Withdrawals',
    link: '/pending-withdrawals',
    icon: WithdrawIcon,
  },
  {
    label: 'All Transactions',
    link: '/transactions',
    icon: BiTransferAlt,
  },
  {
    label: 'All Users',
    link: '/users',
    icon: HiOutlineUsers,
  },
  {
    label: 'Crypto',
    link: '/crypto',
    icon: CryptoIcon,
  },
  {
    label: 'Forex',
    link: '/forex',
    icon: ForexIcon,
  },
  {
    label: 'T-stocks',
    link: '/t-stocks',
    icon: StockIcon,
  },
  {
    label: 'Hard Assets',
    link: '/hard-assets',
    icon: HardAssetIcon,
  },
  {
    label: 'Payout History',
    link: '/payout-history',
    icon: PayoutIcon,
  },
  {
    label: 'Packages',
    link: '/packages',
    icon: PackageIcon,
  },
  {
    label: 'Sub-Admins',
    link: '/sub-admins',
    icon: SettingsIcon,
  },
]
