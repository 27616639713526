import { SyntheticEvent, useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import InputAfterFixShowHider from 'components/form/InputAfterFixShowHider'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import ReCAPTCHA from 'react-google-recaptcha'
import { errorHandler } from 'utils/errorHandler'
type Form = {
  username: string
  password: string
}

export default function LoginForm() {
  const queryClient = useQueryClient()
  const [reCaptchaResponse, setReCaptchaResponse] = useState<string | null>(null)

  const { setToken } = useContext(AuthContext)
  const [form, setForm] = useState<Form>({
    username: '',
    password: '',
  })
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const handleReCaptchaChange = (value: string | null) => {
    setReCaptchaResponse(value)
  }

  const loginMutation = useMutation<{ token: string; message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/auth/login', payload)
        return res.data.data
      } catch (err: any) {
        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        if (!data.token) return
        localStorage.setItem('token', data.token)
        queryClient.invalidateQueries('get-profile')
        setToken(data.token)
      },
    },
  )

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!form.username || !form.password) return toast.error('Please fill all the fields')
    if (!reCaptchaResponse && process.env.REACT_APP_RECAPTCHA_KEY)
      return toast.error('Please verify captcha')

    toast.promise(loginMutation.mutateAsync(form), {
      loading: 'Logging in...',
      success: (res) => res.message || 'Logged in successfully',
      error: (err) => err?.message || 'Failed to login',
    })
  }

  return (
    <>
      <form className='flex flex-col gap-6' onSubmit={onSubmit}>
        <Input onChange={changeHandler} value={form.username} label='Username' name='username' />
        <Input
          onChange={changeHandler}
          value={form.password}
          label='Password'
          name='password'
          type={isPasswordVisible ? 'text' : 'password'}
          afterFix={
            <InputAfterFixShowHider
              isVisible={isPasswordVisible}
              onClick={() => setIsPasswordVisible((prev) => !prev)}
              type='eye'
            />
          }
        />
        {process.env.REACT_APP_RECAPTCHA_KEY && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleReCaptchaChange}
          />
        )}

        <Button className='mt-6' fullWidth disabled={loginMutation.isLoading}>
          Log In
        </Button>
      </form>
    </>
  )
}
