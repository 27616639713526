import NoData from 'components/NoData'

export default function PayoutHistoryPage() {
  return (
    <>
      <table className='mt-8'>
        <thead>
          <tr>
            <td>Timestamp</td>
            <td>Username</td>
            <td>Investment ID</td>
            <td>Payout Amount</td>
            <td>Yield</td>
            <td className='w-44'>Investment History</td>
            <td className='w-44'>Status</td>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      <NoData />
    </>
  )
}
