import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type Props = {
  title?: string
  description?: string
  className?: string
}

export default function AuthLayout({
  title,
  description,
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className='min-h-screen flex p-4'>
      <div
        className={clsx('m-auto bg-dark gradient_border relative p-12 w-full max-w-xl', className)}
      >
        <div className='text-center mb-12'>
          <h2 className='text-2xl font-semibold mb-3'>{title}</h2>
          {description && <p className='text-gray text-base'>{description}</p>}
        </div>
        {children}
      </div>
    </div>
  )
}
