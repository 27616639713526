import moment from 'moment'

export const dateFormatter = (date: Date) => {
  return moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
}

export const numberToHumanDuration = (number: number) => {
  if (!number || number === 0) return ''
  if (number === 1) return 'Day'
  if (number === 7) return 'Week'
  if (number === 30) return 'Month'
  for (let i = 1; i <= 12; i++) {
    if (number === 30 * i) return `${i} Months`
  }
  if (number === 365) return 'Year'
  return `${number} Days`
}

export const tickFormatter = (value: number) => (value > 999 ? `$ ${value / 1000}k` : `$ ${value}`)
