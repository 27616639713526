type Props = React.SVGProps<SVGSVGElement>
export default function PackageIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.3419 1.46094H2.65609C1.19416 1.46094 0.03125 2.62384 0.03125 4.05256V15.9142C0.03125 17.3429 1.19416 18.539 2.65609 18.539H17.3752C18.8039 18.539 20 17.3761 20 15.9142V4.05256C19.9668 2.62384 18.8039 1.46094 17.3419 1.46094ZM11.76 2.78997V6.31192L10.298 5.58095C10.1984 5.54772 10.0987 5.51449 9.99901 5.51449C9.89933 5.51449 9.79966 5.54772 9.69998 5.58095L8.23804 6.31192V2.78997H11.76ZM18.6377 15.9474C18.6377 16.6452 18.0729 17.2432 17.3419 17.2432H2.65609C1.92512 17.21 1.36028 16.6452 1.36028 15.9474V4.05256C1.36028 3.35481 1.92512 2.75675 2.65609 2.75675H6.90901V6.41159C6.90901 6.84353 7.14159 7.24224 7.50707 7.47482C7.87256 7.7074 8.33772 7.7074 8.7032 7.54127L9.99901 6.87676L11.2948 7.54127C11.461 7.64095 11.6603 7.67418 11.8597 7.67418C12.0922 7.67418 12.3248 7.60772 12.5242 7.47482C12.8897 7.24224 13.1222 6.84353 13.1222 6.41159V2.75675H17.3752C18.0729 2.75675 18.671 3.32159 18.671 4.05256V15.9474H18.6377Z'
        fill='currentColor'
      />
      <path
        d='M6.34416 13.9871H3.91867C3.55318 13.9871 3.25415 14.2861 3.25415 14.6516C3.25415 15.0171 3.55318 15.3161 3.91867 15.3161H6.34416C6.70964 15.3161 7.00867 15.0171 7.00867 14.6516C7.00867 14.2861 6.70964 13.9871 6.34416 13.9871Z'
        fill='currentColor'
      />
    </svg>
  )
}
