import clsx from 'clsx'
import { LoaderIcon, toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'

import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import copy from 'copy-to-clipboard'
import addInvestModal from 'features/invest/add-invest.modal'
import investDetailsModal from 'features/invest/invest-details.modal'
import { useState } from 'react'
import { BiCopy } from 'react-icons/bi'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function InvestsPage() {
  const addInvest = useModal(addInvestModal)
  const investDetails = useModal(investDetailsModal)

  const [search, setSearch] = useState<string>('')

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<InvestsResponse, Error>(
    ['invests', search],
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `admin/investment/list?page=${pageParam}&limit=20&query=${search}`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []

  return (
    <>
      <div className='flex justify-between items-end flex-wrap gap-3'>
        <Button onClick={() => addInvest.show()}>Add Invest</Button>
        <Input
          className='!max-w-64 !w-auto'
          inputClassName='!h-12'
          placeholder='Search by investment ID'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx('overflow-y-auto max-w-full', {
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Timestamp</td>
                <td>User ID</td>
                <td>Username</td>
                <td>Investment ID</td>
                <td>Amount</td>
                <td>Yield</td>
                <td className='w-40'>Investment History</td>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.startedAt)}</td>
                  <td>
                    {row.user?._id && (
                      <Button
                        onClick={() => {
                          copy(row.user?._id)
                          toast.success('Copied to clipboard')
                        }}
                        className='rounded-lg mr-2'
                        size='sm'
                      >
                        <BiCopy />
                      </Button>
                    )}{' '}
                    {row.user?._id}
                  </td>
                  <td>{row.user?.username}</td>
                  <td>{row._id}</td>
                  <td>{row.amount?.toFixed(2)} USDT</td>
                  <td>{row.package.type?.toUpperCase()}</td>
                  <td>
                    <Button
                      size='md'
                      onClick={() =>
                        investDetails.show({ userId: row.user?._id, username: row.user?.username })
                      }
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  )
}
