import { Helmet } from 'react-helmet'

import LoginForm from 'features/login/LoginForm'
import AuthLayout from 'layout/AuthLayout'

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>OpenCall Capital | Login</title>
      </Helmet>
      <AuthLayout
        title='Log In Your Account'
        description='Please provide correct username & password'
      >
        <LoginForm />
      </AuthLayout>
    </>
  )
}
