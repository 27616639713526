import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'

import tetherIcon from 'images/tether-icon.png'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiChevronLeft } from 'react-icons/bi'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  realEstate: string
  preciousMetal: string
  energy: string
  totalValue: string
  totalLoss: string
  totalYield: string
  createdAt?: Date
}

export default function AddHardAssetPage() {
  const queryClient = useQueryClient()
  const [form, setForm] = useState<Form>({
    realEstate: '',
    preciousMetal: '',
    energy: '',
    totalValue: '',
    totalLoss: '',
    totalYield: '',
    createdAt: new Date(),
  })

  const [errors, setErrors] = useState<Form>({
    realEstate: '',
    preciousMetal: '',
    energy: '',
    totalValue: '',
    totalLoss: '',
    totalYield: '',
  })

  const addCrypto = useMutation(
    async () => {
      try {
        const res = await privateRequest.post('admin/hardAsset', form)
        console.log(res)
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        setForm({
          realEstate: '',
          preciousMetal: '',
          energy: '',
          totalValue: '',
          totalLoss: '',
          totalYield: '',
          createdAt: new Date(),
        })
        queryClient.invalidateQueries('cryptos')
      },
    },
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (isNaN(+value)) return
    setForm((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !form.realEstate ||
      !form.preciousMetal ||
      !form.energy ||
      !form.totalValue ||
      !form.totalLoss ||
      !form.totalYield
    ) {
      setErrors((prev) => ({
        ...prev,
        realEstate: !form.realEstate ? 'Real Estate is required' : '',
        preciousMetal: !form.preciousMetal ? 'Precious Metal is required' : '',
        energy: !form.energy ? 'Energy is required' : '',
        totalValue: !form.totalValue ? 'Total Value is required' : '',
        totalLoss: !form.totalLoss ? 'Total Loss is required' : '',
        totalYield: !form.totalYield ? 'Total Yield is required' : '',
      }))
      return
    }
    toast.promise(addCrypto.mutateAsync(), {
      loading: 'Adding new price...',
      success: 'New price added',
      error: 'Error while adding new price',
    })
  }

  return (
    <div>
      <h2 className='text-lg font-medium mb-10'>
        <Link to='/hard-assets' className='mr-2'>
          <BiChevronLeft className='inline-block' size={30} />
        </Link>
        Add New Price{' '}
      </h2>

      <form onSubmit={submitHandler} className='grid grid-cols-2 gap-20'>
        <Input
          label='Real Estate'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.realEstate}
          onChange={handleChange}
          name='realEstate'
          error={!!errors.realEstate}
          helperText={errors.realEstate}
        />
        <Input
          label='Precious Metal'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.preciousMetal}
          onChange={handleChange}
          name='preciousMetal'
          error={!!errors.preciousMetal}
          helperText={errors.preciousMetal}
        />
        <Input
          label='Energy'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.energy}
          onChange={handleChange}
          name='energy'
          error={!!errors.energy}
          helperText={errors.energy}
        />
        <Input
          label='Total Value'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalValue}
          onChange={handleChange}
          name='totalValue'
          error={!!errors.totalValue}
          helperText={errors.totalValue}
        />
        <Input
          label='Total Yield'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalYield}
          onChange={handleChange}
          name='totalYield'
          error={!!errors.totalYield}
          helperText={errors.totalYield}
        />
        <Input
          label='Total Losses'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalLoss}
          onChange={handleChange}
          name='totalLoss'
          error={!!errors.totalLoss}
          helperText={errors.totalLoss}
        />
        <Button disabled={addCrypto.isLoading} fullWidth>
          Add
        </Button>
      </form>
    </div>
  )
}
