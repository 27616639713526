import clsx from 'clsx'
import Button from 'components/form/Button'
import { privateRequest } from 'config/axios.config'
import tetherIcon from 'images/tether-icon.png'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function HardAssetsPage() {
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    HardAssetResponse,
    Error
  >(
    'hardAsset',
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(`admin/hardAsset?page=${pageParam}&limit=20`)
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []
  return (
    <>
      <Button to='/hard-assets/add'>Add New</Button>

      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx({
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Timestamp</td>
                <td>Real Estate</td>
                <td>Precious Metals</td>
                <td>Energy</td>
                <td>Total Value</td>
                <td>Total Yield</td>
                <td>Total Losses</td>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.createdAt)}</td>
                  <td>
                    <div className='flex items-center gap-1'>
                      <img src={tetherIcon} alt='' /> {row.realEstate}
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center gap-1'>
                      <img src={tetherIcon} alt='' /> {row.preciousMetal}
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center gap-1'>
                      <img src={tetherIcon} alt='' /> {row.energy}
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center gap-1'>
                      <img src={tetherIcon} alt='' /> {row.totalValue}
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center gap-1'>
                      <img src={tetherIcon} alt='' /> {row.totalYield}
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center gap-1'>
                      <img src={tetherIcon} alt='' /> {row.totalLoss}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  )
}
