import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'
import moment from 'moment'
import { useQuery } from 'react-query'

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { tickFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function TotalInvestLineChart() {
  const { data } = useQuery<ChartData, Error>('chartData', async () => {
    try {
      const response = await privateRequest.get('admin/dashboard/chartData')
      return response.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card pb-5'>
      <CardTitle title='Total Invested value' />
      <div className='h-[400px]'>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            width={500}
            height={300}
            data={data?.chartData?.map((item) => ({
              name: item._id,
              Amount: item.total,
            }))}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke='#D2EAFF' vertical={false} strokeWidth={0.5} opacity={0.5} />
            <XAxis
              dataKey='name'
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => moment(value).format('MMM DD')}
            />
            <YAxis
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
              orientation='right'
              tickCount={8}
              tickFormatter={(value) => tickFormatter(value)}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#1E2A47',
                border: 'none',
                borderRadius: '10px',
                color: '#fff',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14px',
                padding: '10px',
                textAlign: 'center',
              }}
              formatter={(value) => [`${value?.toLocaleString()} USDT`, 'Amount']}
              labelFormatter={(value) => moment(value).format('MMM DD, YYYY')}
            />
            <Line type='monotone' dataKey='Amount' stroke='#82ca9d' />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
