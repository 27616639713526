import { privateRequest } from 'config/axios.config'
import AnalyticCard from './AnalyticCard'

import totalInvest from 'images/analytics/total-invest.png'
import totalMember from 'images/analytics/total-member.png'
import totalWithdrawal from 'images/analytics/total-withdrawal.png'
import yieldImg from 'images/analytics/yield.png'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function Analytics() {
  const { data } = useQuery<Analytics, Error>('analytics', async () => {
    try {
      const response = await privateRequest.get('admin/dashboard/statistics')
      return response.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='grid sm:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-5 gap-3'>
      <AnalyticCard icon={totalMember} title='Total Members' value={data?.memberCount ?? 0} />
      <AnalyticCard
        icon={totalInvest}
        title='Total Investment'
        value={(data?.totalInvested ?? 0) + ' USDT'}
      />
      <AnalyticCard
        icon={totalWithdrawal}
        title='Total Withdrawals'
        value={(data?.totalWithdrawal ?? 0) + ' USDT'}
      />
      <AnalyticCard icon={yieldImg} title='Daily Yield' value={(data?.dailyYield ?? 0) + ' USDT'} />
      <AnalyticCard
        icon={yieldImg}
        title='Weekly Yield'
        value={(data?.weeklyYield ?? 0) + ' USDT'}
      />
    </div>
  )
}
