import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'

import bitcoinFundIcon from 'images/bitcoin-fund-icon.png'
import tetherIcon from 'images/tether-icon.png'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiChevronLeft } from 'react-icons/bi'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  bitcoinFund: string
  liquidityShare: string
  earlyTokenStage: string
  totalValue: string
  totalLoss: string
  totalYield: string
  createdAt?: Date
}

export default function AddCryptoPage() {
  const queryClient = useQueryClient()
  const [form, setForm] = useState<Form>({
    bitcoinFund: '',
    liquidityShare: '',
    earlyTokenStage: '',
    totalValue: '',
    totalLoss: '',
    totalYield: '',
    createdAt: new Date(),
  })

  const [errors, setErrors] = useState<Form>({
    bitcoinFund: '',
    liquidityShare: '',
    earlyTokenStage: '',
    totalValue: '',
    totalLoss: '',
    totalYield: '',
  })

  const addCrypto = useMutation(
    async () => {
      try {
        const res = await privateRequest.post('admin/crypto', form)
        console.log(res)
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        setForm({
          bitcoinFund: '',
          liquidityShare: '',
          earlyTokenStage: '',
          totalValue: '',
          totalLoss: '',
          totalYield: '',
          createdAt: new Date(),
        })
        queryClient.invalidateQueries('cryptos')
      },
    },
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (isNaN(+value)) return
    setForm((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !form.bitcoinFund ||
      !form.liquidityShare ||
      !form.earlyTokenStage ||
      !form.totalValue ||
      !form.totalLoss ||
      !form.totalYield
    ) {
      setErrors((prev) => ({
        ...prev,
        bitcoinFund: !form.bitcoinFund ? 'Bitcoin Fund is required' : '',
        liquidityShare: !form.liquidityShare ? 'Liquidity Share is required' : '',
        earlyTokenStage: !form.earlyTokenStage ? 'Early Token Stage is required' : '',
        totalValue: !form.totalValue ? 'Total Value is required' : '',
        totalLoss: !form.totalLoss ? 'Total Loss is required' : '',
        totalYield: !form.totalYield ? 'Total Yield is required' : '',
      }))
      return
    }
    toast.promise(addCrypto.mutateAsync(), {
      loading: 'Adding new crypto...',
      success: 'Crypto added successfully',
      error: 'Something went wrong',
    })
  }

  return (
    <div>
      <h2 className='text-lg font-medium mb-10'>
        <Link to='/crypto' className='mr-2'>
          <BiChevronLeft className='inline-block' size={30} />
        </Link>
        Add New Price{' '}
      </h2>

      <form onSubmit={submitHandler} className='grid grid-cols-2 gap-20'>
        <Input
          label='Bitcoin Fund'
          afterFix={<img className='-translate-y-1' src={bitcoinFundIcon} />}
          value={form.bitcoinFund}
          onChange={handleChange}
          name='bitcoinFund'
          error={!!errors.bitcoinFund}
          helperText={errors.bitcoinFund}
        />
        <Input
          label='Liquidity Shares'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.liquidityShare}
          onChange={handleChange}
          name='liquidityShare'
          error={!!errors.liquidityShare}
          helperText={errors.liquidityShare}
        />
        <Input
          label='Early Token Stage'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.earlyTokenStage}
          onChange={handleChange}
          name='earlyTokenStage'
          error={!!errors.earlyTokenStage}
          helperText={errors.earlyTokenStage}
        />
        <Input
          label='Total Value'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalValue}
          onChange={handleChange}
          name='totalValue'
          error={!!errors.totalValue}
          helperText={errors.totalValue}
        />
        <Input
          label='Total Yield'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalYield}
          onChange={handleChange}
          name='totalYield'
          error={!!errors.totalYield}
          helperText={errors.totalYield}
        />
        <Input
          label='Total Losses'
          afterFix={<img className='-translate-y-1' src={tetherIcon} />}
          value={form.totalLoss}
          onChange={handleChange}
          name='totalLoss'
          error={!!errors.totalLoss}
          helperText={errors.totalLoss}
        />
        <Button disabled={addCrypto.isLoading} fullWidth>
          Add
        </Button>
      </form>
    </div>
  )
}
