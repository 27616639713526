import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { useState } from 'react'
import Modal from '.'

type Props = {
  title?: string
  phase?: ButtonColorType
  textRequired?: boolean
  description?: string
  labelText?: string
}

export default NiceModal.create(
  ({
    title,
    phase = 'primary',
    description = '',
    textRequired = false,
    labelText = 'Enter Note',
  }: Props) => {
    const modal = useModal()

    const [text, setText] = useState('')

    return (
      <Modal
        title={title}
        visible={modal.visible}
        onCancel={() => modal.hide()}
        className='max-w-md'
      >
        <h2>{description}</h2>
        {textRequired && (
          <div className='mt-4'>
            <Input label={labelText} value={text} onChange={(e) => setText(e.target.value)} />
          </div>
        )}
        <Modal.Footer>
          <Button
            onClick={() => {
              modal.reject()
              modal.hide()
            }}
            size='md'
            color='default'
          >
            No
          </Button>
          <Button
            onClick={() => {
              modal.resolve(text)
              modal.hide()
            }}
            size='md'
            color={phase}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  },
)
