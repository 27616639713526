import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import Tabs from 'components/Tabs'
import Button from 'components/form/Button'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { LoaderIcon, toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

const tabs: TabOption[] = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Confirmed',
    value: 'confirmed',
  },
]

export default function WithdrawalsPage() {
  const queryClient = useQueryClient()

  const confirm = useModal(confirmationModal)
  const [activeTab, setActiveTab] = useState<TabOption>(tabs[0])

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    TransactionResponse,
    Error
  >(
    ['withdrawals', activeTab.value],
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `admin/wallet/transactions?page=${pageParam}&limit=20&status=${activeTab.value}&criteria=withdraw`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const approve = useMutation<
    { message: string },
    Error,
    {
      withdrawId: string
      hash: string
    }
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/wallet/releaseWithdraw', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('withdrawals')
      },
    },
  )

  const decline = useMutation<
    { message: string },
    Error,
    {
      withdrawId: string
      reason: string
    }
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/wallet/declineWithdraw', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('withdrawals')
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []

  return (
    <>
      <div className='flex'>
        <Tabs options={tabs} selectHandler={setActiveTab} selected={activeTab} />
      </div>

      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx({
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Timestamp</td>
                <td>Username</td>
                <td>Wallet Address</td>
                <td>Amount</td>
                <td>Currency</td>
                <td className='w-28'>{activeTab.value === 'pending' ? 'Action' : 'Status'}</td>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.createdAt)}</td>
                  <td>{row.user.username}</td>
                  <td>{row._id}</td>
                  <td>{row.amount?.toFixed(8)}</td>
                  <td>{row.withdrawalCurrency}</td>
                  <td className='flex gap-3'>
                    {activeTab.value === 'pending' ? (
                      <>
                        <Button
                          onClick={() =>
                            confirm
                              .show({
                                title: 'Approve Withdrawal',
                                textRequired: true,
                                labelText: 'Hash',
                              })
                              .then((hash) =>
                                toast.promise(
                                  approve.mutateAsync({
                                    hash: hash as string,
                                    withdrawId: row._id,
                                  }),
                                  {
                                    loading: 'Approving...',
                                    success: (r) => r.message ?? 'Approved',
                                    error: (r) => r.message ?? 'Failed to approve',
                                  },
                                ),
                              )
                          }
                          size='md'
                        >
                          Approve
                        </Button>
                        <Button
                          onClick={() =>
                            confirm
                              .show({
                                title: 'Decline Withdrawal',
                                textRequired: true,
                              })
                              .then((note) =>
                                toast.promise(
                                  decline.mutateAsync({
                                    reason: note as string,
                                    withdrawId: row._id,
                                  }),
                                  {
                                    loading: 'Declining...',
                                    success: (r) => r.message ?? 'Decline',
                                    error: (r) => r.message ?? 'Failed to declined',
                                  },
                                ),
                              )
                          }
                          size='md'
                          variant='outlined'
                        >
                          Decline
                        </Button>
                      </>
                    ) : (
                      <Button size='md' color='success'>
                        Approved
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  )
}
