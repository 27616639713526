import Logo from 'components/Logo'
import { AuthContext } from 'context/AuthContext'
import { PropsWithChildren, useContext } from 'react'

type Props = {
  title: string
}

export default function PageWrapper({ title, children }: PropsWithChildren<Props>) {
  const { setDrawerShow } = useContext(AuthContext)

  return (
    <div>
      <div className='flex lg:hidden justify-center pt-4'>
        <Logo />
      </div>
      <header className='flex flex-wrap gap-2 items-center px-3 sm:px-9 pt-10'>
        <svg
          className='lg:hidden cursor-pointer'
          onClick={() => setDrawerShow(true)}
          stroke='currentColor'
          fill='currentColor'
          strokeWidth='0'
          viewBox='0 0 1024 1024'
          height='25'
          width='25'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z'></path>
        </svg>
        <h2 className='text-2xl font-medium hidden md:inline-block'>
          <div className='flex items-center'>{title}</div>
        </h2>
      </header>
      <main className='p-4 md:p-9'>{children}</main>
    </div>
  )
}
