import Devider from 'components/Devider'
import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'
import Analytics from 'features/dashboard/Analytics'
import TotalInvestLineChart from 'features/dashboard/TotalInvestLineChart'
import { useQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

export default function Dashboard() {
  const { data } = useQuery<Transaction[], Error>('last-transactions', async () => {
    try {
      const response = await privateRequest.get('admin/wallet/transactions?limit=7')
      return response.data.data.docs
    } catch (error) {
      errorHandler(error)
    }
  })
  return (
    <>
      <Analytics />
      <Devider />
      <TotalInvestLineChart />
      <Devider />
      <div className='card'>
        <CardTitle title='Latest Transactions' />
        <table className='mt-8'>
          <thead>
            <tr>
              <td>Timestamp</td>
              <td>Username</td>
              <td>Amount</td>
              <td>Address</td>
              <td>Note</td>
              <td>Type</td>
              <td className='w-28'>Status</td>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, i) => (
              <tr key={i}>
                <td>{dateFormatter(row.createdAt)}</td>
                <td>{row.user.username}</td>
                <td>{row.amount?.toFixed(8)}</td>
                <td>{row.address}</td>
                <td>{row.note}</td>
                <td>{row.criteria}</td>
                <td className={`text-${StatusColorFinder(row.status)} capitalize`}>{row.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
