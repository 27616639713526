import clsx from 'clsx'
import { useRef, useState } from 'react'
import { LoaderIcon } from 'react-hot-toast'

import { BsChevronDown } from 'react-icons/bs'
import useClickOutside from 'utils/useClickOutside'
// import useClickOutside from "utils/useClickOutside";

type Props = {
  value?: Option
  values?: Option[]
  placeholder?: string
  options: Option[]
  name?: string
  className?: string
  containerClass?: string
  listClass?: string
  align?: 'left' | 'right'
  error?: boolean
  helperText?: string
  label?: string
  isEmpty?: boolean
  multiple?: boolean
  isLoading?: boolean
  search?: string
  onSearch?: (value: string) => void
  onChange: (target: SelectElement) => void
}

const Select = ({
  value,
  placeholder,
  options,
  onChange,
  name = '',
  className = '',
  containerClass = '',
  listClass = '',
  align = 'left',
  error,
  helperText,
  isEmpty = false,
  label,
  multiple,
  values,
  onSearch,
  search,
  isLoading,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => {
    setOpen((prev) => !prev)
  }
  const closeHandler = () => {
    setOpen(false)
  }

  const ref = useRef(null)
  useClickOutside(ref, closeHandler)

  const selectHandler = (value: Option) => {
    onChange({ target: { value, name } })
    closeHandler()
  }

  return (
    <div ref={ref} className={clsx('relative inline-block', containerClass)}>
      {label && (
        <label htmlFor={name} className='block text-xs font-normal text-white mb-2'>
          {label}
        </label>
      )}
      <div
        onClick={toggle}
        className={clsx(
          'w-full cursor-pointer select-none flex items-center border-b border-slate-200 border-solid h-12 px-3',
          error && 'border-red-400 focus:border-red-500 focus:ring-red-500',
          className,
        )}
      >
        <span className={clsx('mr-3 whitespace-nowrap text-white')}>
          {multiple ? (
            <>{values?.map((el, i) => `${i !== 0 ? ',' : ''} ${el.label}`)}</>
          ) : (
            <>{value?.label ?? placeholder}</>
          )}
          {onSearch && (
            <input
              type='text'
              value={search}
              className='ml-3 bg-transparent text-white w-full outline-none'
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
        </span>{' '}
        <div className='ml-auto flex items-center gap-2'>
          {isLoading && <LoaderIcon />}
          <BsChevronDown />
        </div>
      </div>
      <ul
        className={clsx(
          'absolute top-full z-20 min-w-full w-max shadow-sm rounded-md bg-slate-800 transition-all max-h-80 overflow-y-auto',
          {
            'visible opacity-100': open,
            'invisible opacity-0': !open,
          },
          align === 'left' ? 'left-0' : 'right-0',
          listClass,
        )}
      >
        {isEmpty && (
          <li
            role='button'
            className='px-5 py-3 hover:bg-slate-900 cursor-pointer'
            onClick={() => selectHandler({ label: 'Select', value: '' })}
          >
            Select
          </li>
        )}
        {options.map((option, i) => (
          <li
            role='button'
            key={i}
            className={clsx('px-5 py-3 hover:bg-slate-900 cursor-pointer', {
              'bg-slate-700': values?.includes(option),
            })}
            onClick={() => selectHandler(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>
      <p className='text-red-500 text-sm font-semibold my-1'>{helperText}</p>
    </div>
  )
}

export default Select
